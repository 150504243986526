import _ from "lodash";
import { clinics, members } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      status: false,
      activeTab: "all",
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      params: "",
      key: 0,
      form: {
        title: "",
        sub_title: "",
        description: "",
        address: "",
        latitude: "",
        langitude: "",
        mobile_number: "",
        email: "",
        languages: [],
        type: "",
      },
      allLanguages: [
        {
          value: "english",
          label: "English",
        },
        {
          value: "hindi",
          label: "Hindi",
        },
        {
          value: "marathi",
          label: "Marathi",
        },
      ],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "title",
        },
        {
          key: "sub_title",
          label: "Sub Title",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      clinic_tabs: {},
      banner_image_url: "",
      logo_image_url: "",
      banner_image: "",
      logo_image: "",
      custom_badge_icon_url: null,
      edit: {
        custom_badge_icon_url: null,
        banner_image_url: "",
        logo_image_url: "",
      },
      typeOpt: [
        {
          value: "hospital",
          text: "Hospital",
        },
        {
          value: "clinic",
          text: "Clinic",
        },
      ],
      serviceList: [],
      socialLinks: [],
      maxFileSize: 2 * 1000 * 1000,
      fileErrors: [],
      memberList: [],
    };
  },
  methods: {
    addPatientStory() {
      this.form.patient_stories.push({
        id: this.form.patient_stories.length,
        url: "",
        meta_title: "",
        meta_description: "",
        meta_image: null,
      });
    },
    removeImageStory(index) {
      if (this.form.patient_stories.length > 1) {
        this.form.patient_stories.splice(index, 1);
      }
    },
    fetchMemberList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.memberList = [];
      if (query != "" && query != null) {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getMembers;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.memberList = data.response.data.map((member) => ({
            member_id: member.id,
            fnameAndLname: `${member.fname} ${member.lname} - ${member.mobile_number}`,
          }));
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),
    addImage() {
      this.form.clinic_gallery.push({
        id: this.form.clinic_gallery.length,
        image: "",
        is_active: 0,
        previewUrl: "",
      });
    },
    removeImage(index) {
      if (this.form.clinic_gallery.length > 1) {
        this.form.clinic_gallery.splice(index, 1);
      }
    },
    handleFileChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded file is more than 2MB",
          });
          event.target.value = "";
          return;
        }
        this.$set(this.form.clinic_gallery, index, {
          id: this.form.clinic_gallery[index].id,
          image: file,
          is_active: this.form.clinic_gallery[index].is_active,
          previewUrl: URL.createObjectURL(file),
        });
      }
    },
    async getSocialLinks() {
      try {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getSocialLinks;
        const res = await this.getRequest(url);
        if (res.status) {
          this.socialLinks = res.response;
          this.socialLinks.forEach((element) => {
            this.form.social_links[element.value] = "";
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    addFaq() {
      this.form.faqs.push({
        id: this.form.faqs.length,
        question: "",
        answer: "",
      });
    },
    removeFaq(index) {
      if (this.form.faqs.length > 1) {
        this.form.faqs.splice(index, 1);
      }
    },
    addOption() {
      let length = this.form.amenity.length;
      this.form.amenity.push({
        id: length,
        name: "",
      });
    },
    removeOption(key) {
      if (key == 0) return;
      this.form.amenity.splice(key, 1);
    },
    readFile(e, txt) {
      if (txt == "banner_image") {
        this.banner_image = e.target.files[0];
        if (this.banner_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.banner_image.reset();
          this.banner_image_url = "";
          this.edit.banner_image_url = "";
          this.banner_image = "";
          return false;
        } else {
          this.banner_image_url = URL.createObjectURL(this.banner_image);
          this.edit.banner_image_url = URL.createObjectURL(this.banner_image);
        }
        return true;
      } else if (txt == "logo_image") {
        this.logo_image = e.target.files[0];
        if (this.logo_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.logo_image.reset();
          this.logo_image_url = "";
          this.edit.logo_image_url = "";
          this.logo_image = "";
          return false;
        } else {
          this.logo_image_url = URL.createObjectURL(this.logo_image);
          this.edit.logo_image_url = URL.createObjectURL(this.logo_image);
        }
        return true;
      } else if (txt == "custom_badge_icon") {
        this.custom_badge_icon = e.target.files[0];
        if (this.custom_badge_icon.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is more than 2MB",
          });
          this.$refs.custom_badge_icon.reset();
          this.custom_badge_icon_url = "";
          this.edit.custom_badge_icon_url = "";
          this.custom_badge_icon = "";
          return false;
        } else {
          this.custom_badge_icon_url = URL.createObjectURL(
            this.custom_badge_icon
          );
          this.edit.custom_badge_icon_url = "";
        }
      }
    },
    convertToPlainText(htmlString) {
      return htmlString.replace(/<[^>]+>/g, "");
    },
    searchFor() {
      if (this.filter.length > 1) this.fetchData("search");
      else if (this.filter.length == 0) this.fetchData("search");
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData("search");
        }
      } else if (this.filter.length == 0) this.fetchData("search");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = clinics?.getClinics;
        if (pagination == "search") {
          url = clinics?.getClinics + "?search=" + this.filter;
        } else if (pagination) {
          url = url + "?page=" + pagination;
        }
        const data = await this.getRequest(url);
        if (data.status) {
          this.tableData = data?.response;
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteClinic(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = clinics?.getClinics + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchSingleHospital(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getClinics}/${id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.form.title = responseData?.title ?? "";
          this.form.sub_title = responseData?.sub_title ?? "";
          this.form.mobile_number = responseData?.mobile_number ?? "";
          this.form.latitude = responseData?.latitude ?? "";
          this.form.langitude = responseData?.longitude ?? "";
          this.form.description = responseData?.description ?? "";
          this.form.email = responseData?.email ?? "";
          this.form.address = responseData?.address ?? "";
          this.form.type = responseData?.type ?? "";
          this.edit.banner_image_url = responseData?.banner_image ?? "";
          this.edit.logo_image_url = responseData?.logo_image ?? "";
          if (responseData?.languages) {
            let parseLanguage = JSON.parse(responseData?.languages);
            this.form.languages = parseLanguage?.map((item) => {
              return {
                value: item ?? "",
                label: item
                  ? item?.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
                  : "",
              };
            });
          }
          let clinic_tabs = {};
          if (responseData.clinic_tabs) {
            if (typeof responseData.clinic_tabs == "string") {
              clinic_tabs = JSON.parse(responseData.clinic_tabs);
            } else {
              clinic_tabs = responseData.clinic_tabs;
            }
          }
          if (responseData.clinic_tabs) {
            Object.entries(clinic_tabs).forEach((d) => {
              clinic_tabs[d[0]].active =
                d[1].active == "on" || d[1].active == true ? true : false;
            });
            this.clinic_tabs = clinic_tabs;
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = clinics?.getClinics;
        if (this.$route.name == "edit-clinic") {
          url = clinics?.getClinics + "/" + this.$route.params.id;
        }
        let dataAppend = new FormData();
        let languages = [];
        this.form.languages.map((item) => {
          languages.push(item.value);
        });
        if (languages.length > 0) {
          dataAppend.append("languages", JSON.stringify(languages));
        }
        if (this.banner_image_url) {
          dataAppend.append("banner_image", this.banner_image);
        }
        if (this.logo_image_url) {
          dataAppend.append("logo_image", this.logo_image);
        }
        for (var key in this.form) {
          if (
            key != "languages" &&
            key != "banner_image" &&
            key != "logo_image"
          ) {
            dataAppend.append(key, this.form[key]);
          }
        }
        if (this.$route.name == "edit-clinic") {
          dataAppend.append("clinic_tabs", JSON.stringify(this.clinic_tabs));
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          if (this.$route.name == "add-clinic") {
            this.$router.push(`/clinic`);
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = clinics?.updateClinicStatus + "/" + id;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          this.tableData.data[index] = data?.response;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchServices(query) {
      this.$store.commit("loader/updateStatus", false);
      this.serviceList = [];
      if (query != null && query != "") {
        this.$store.commit("loader/updateStatus", true);
        const url = clinics?.getServiceList;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.serviceList = data.response.data;
        }
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `&page=${value}`;
          this.fetchData(value);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (this.$route.name == "add-clinic" || this.$route.name == "edit-clinic") {
      if (this.$route.name == "edit-clinic") {
        this.fetchSingleHospital(this.$route.params.id);
      }
    }
  },
};
